body{
  font-size:13px;
}

.required{
  &:after{
    content:"*";
    font-weight:bold;
    color:$red;
  }
}

h2{
  font-size:20px;
  margin:2em 0 1em;
}


.custom-select-inline{
  display:inline;
  width:auto;
}
